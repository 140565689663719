import type { JSX } from 'solid-js';

type Props = JSX.SvgSVGAttributes<SVGSVGElement>;

export function ImageOverlay(props: Props) {
	return (
		<svg
			{...props}
			xmlns="http://www.w3.org/2000/svg"
			fill="none"
			viewBox="0 0 1416 20"
			class={`pointer-events-none z-20 w-full fill-white ${props.class}`}
		>
			<path
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M1362.19 1.58s18.49 2.737 16.29 2.076l20.05-.57s12.84-.403 17.47-.47V20H0V3.23c14.034.262 27.453.492 27.629.448l6.484.228s11.503-.627 12.6-.81l24.655-.17s21.176-.456 21.255-.456l13.803-.513 10.406-.73 38.745.091 10.928-.57s16.471 1.722 14.118 1.095c0 0 27.111 1.995 25.15 2.052 0 0 9.02-.057 8.288-.228l31.242 2.942s30.666 2.77 31.686 2.11l10.405.444s29.255 2.224 26.536 3.136l10.484-1.608 3.529.696 19.87 1.048 10.693.776 3.817-.696s10.902.525 10.065.114l13.726.183s20.392 2.03 17.96 1.756l13.438-.148s5.46.53 5.248.775c6.998-2.544 16.658-1.676 20.661 2.212l13.176-2.166s8.523-.673 9.7-.821l4.497 1.014s8.81 0 10.954-.193c0 0 22.562 1.676 21.882 1.847l24.262-1.232s361.099-.66 362.903-.615h11.032s5.334-.821 5.961-.821l20-.08 18.484-.559 22.431 1.46s21.988 1.083 22.144 1.55c2.374-1.383 4.292-.834 6.03-.336.827.237 1.613.462 2.389.462l22.484.787c-.08-.069 7.06-.069 7.06-.069.1 0 11.71-2.44 12.73-2.44l14.56-.296s18.83.296 18.38.114l8.29-.616s16.08.718 16.91.787l1.77-.13c5.86-.438 24.17-1.803 22.94-1.57 0 0 16.1-2.052 15.82-2.2l30.95-4.31 4.84.273.65.274s1.02.342 2.69-1.243c0 0 45.52-3.193 45.73-3.193l12.39-.627h3.37c4.55.009 47.1.043 68 .06l11.46.009 14.24-.342s13.49-.685 15.61-.81l12.05.205Z"
			/>
		</svg>
	);
}
